// "Leaderboard-Table-Detailed-Score-Modal"
.ltdsm {
  padding-left: 0;
  padding-right: 0;

  &_team-info {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 1rem;
  }

  &_scorecard {
    border-radius: 5px;
    //border-width: 1px;
    //border-style: solid;
    //border-color: $lightBlue;
    font-size: 0.8em;
    font-weight: bold;

    &_section {
      display: flex;
      flex-direction: row;

      &_col {
        flex: 1;

        &_0 {
          .ltdsm_scorecard_section_col_cell {
            text-align: left !important;
            padding-left: 7px;
            padding-right: 12px;
          }
        }

        &_cell {
          padding-top: 4px;
          padding-bottom: 2px;
          text-align: center;

          &_top {
            background-color: $lightBlue;
            color: $white;
          }

          &_mid {
            color: $muted;
          }

          &_bottom {
            color: $darkBlue;
          }
        }
      }
    }
  }

  &_score-info {
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    &_item {
      @include flex-row-item-defined-width-helper(10px, 3, 33%);
      //width: calc(100% / 3);
      display: flex;
      flex-direction: row;
      gap: 5px;

      &_ball {
        width: 14px;
        height: 14px;
        border-radius: 50%;

        &_ace {
          background-color: #6e97bc;
        }

        &_eagle {
          background-color: #50ace5;
        }

        &_par {
          border: 1px solid gray;
        }

        &_birdie {
          background-color: rgba($lightBlue, 0.5);
        }

        &_bogey {
          background-color: $yellow;
        }

        &_double-bogey {
          background-color: #ed1c24;
        }
      }

      &_label {
        font-size: 8pt;
      }
    }
  }

  &_score {
    &_ace {
      background-color: #6e97bc;
      color: white;
    }

    &_eagle {
      background-color: #50ace5;
      color: white;
    }

    &_birdie {
      background-color: #4B7EDFFF;
      color: white;
    }

    &_bogey {
      background-color: $yellow;
      color: white;
    }

    &_double-bogey {
      background-color: #ed1c24;
      color: white;
    }
  }
}
