@import "./bootstrap-override";

.hexagon-base {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexagon-before-after-base {
  content: "";
  position: absolute;
  width: 0;
}

.hexagon-before-base {
  bottom: 100%;
}

.hexagon-after-base {
  top: 100%;
  width: 0;
}

/** Score Card Black **/

.hexagon {
  @extend .hexagon-base;
  width: 45px;
  height: 25.98px;
  margin: 12.99px 0;
  background-color: $dark;

  &:hover {
    background-color: $gray-600;

    &:before {
      border-bottom-color: $gray-600;
    }

    &:after {
      border-top-color: $gray-600;
    }
  }
}

.hexagon:before,
.hexagon:after {
  @extend .hexagon-before-after-base;
  border-left: 22.5px solid transparent;
  border-right: 22.5px solid transparent;
}

.hexagon:before {
  @extend .hexagon-before-base;
  border-bottom: 12.99px solid $dark;
}

.hexagon:after {
  @extend .hexagon-after-base;
  border-top: 12.99px solid $dark;
}

/** Score Entry White **/

.hexagon__score-entry {
  @extend .hexagon-base;
  width: 80px;
  height: 46.19px;
  margin: 23.09px 0;
  background-color: $white;
}

.hexagon__score-entry:before,
.hexagon__score-entry:after {
  @extend .hexagon-before-after-base;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
}

.hexagon__score-entry:before {
  @extend .hexagon-before-base;
  border-bottom: 23.09px solid $white;
}

.hexagon__score-entry:after {
  @extend .hexagon-after-base;
  border-top: 23.09px solid $white;
}
