@import "roboto-condensed";
@import "./bootstrap-override";
@import "./flex-row-item-width-helper";

@import "./animated-board2-row";
@import "./animated-board2-row-content-swiper";
@import "./animated-leaderboard-page";
@import "./animated-page-header";
@import "./animated-page-video-background";
@import "./animated-player-details";
@import "./animated-player-details-row";
@import "./animated-player-details-row-content-swiper";
@import "./navigationBar";
@import "./react-bootstrap-table.scss";
@import "./score-card.scss";
@import "./hexagon.scss";
@import "./top-banner.scss";
@import "./landing-page.scss";
@import "./chat-page.scss";
@import "./leader-boards.scss";
@import "./custom-modal-header";
@import "./score-entry-modal";
@import "./custom-page-spacing";
@import "./ads";
@import "./checkbox";
@import "./toggle-leaderboard";
@import "./leaderboard-table-detailed-score-modal";

#root {
  //height: 100vh;
}

html { height: 100%; overflow:auto; }
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: "Nunito Sans", sans-serif;
}

h1 {
  font-weight: 800; // extra bold
}

h2, h3 {
  font-weight: 700; // bold
}

h4, h5, h6 {
  font-weight: 600; // semi-bold
}

label {
  font-weight: bold;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
