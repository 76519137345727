.animated-player-details-row-content-swiper {
  background-color: $animated-leaderboard-page-black1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  transition: all 1s;

  &_incoming-start {
    opacity: 0;
    left: calc(#{$animated-board2-row-animation-offset} * -1);
  }

  &_resting {
    left: 0;
    opacity: 1;
  }

  &_outbound {
    opacity: 0;
    left: $animated-board2-row-animation-offset;
  }

  &_details {
    width: 67%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 0 calc(#{$animated-leaderboard-page-table-gap} * 2);

    &_label {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 0;
      color: white;
    }

    &_subtitle {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 0;
      color: $animated-leaderboard-page-blue3;
      font-weight: 400;
    }
  }

  &_details-shotgun {
    width: 89%;
  }

  &_time {
    width: 22%;
    height: 100%;
    background-color: $animated-leaderboard-page-blue2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 40px;
      line-height: 40px;
      color: white;
    }
  }

  &_hole {
    width: 11%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      text-align: center;
      margin-bottom: 0;
      font-size: 40px;
      line-height: 40px;
      color: $animated-leaderboard-page-blue3;
    }
  }

  &_hole-shotgun {
    background-color: $animated-leaderboard-page-blue2;

    p {
      color: white;
    }
  }
}