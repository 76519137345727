.banner-container {
  //@media screen and (min-width: 800px) {
  //  max-height: 140px;
  //}

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  //@extend .bg-dark;

  img {
    max-height: 100%;
    width: 100%;
    object-fit: contain;

    @media screen and (min-width: 800px) {
      max-height: 140px;
    }
  }
}
