.animated-page-header {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px;
  background-color: $animated-leaderboard-page-bg;

  &_brand {
    flex: 3;
    aspect-ratio: 320 / 100;
    background: $white;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &_details {
    height: 100%;
    flex: 7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.7rem;
    width: 0; // cheat to make the width explicitly 0 so overflow properties for text work as expected, while the flex properties distribute the real width

    &_c-name {
      margin-bottom: 0.7rem;
      font-size: 16px;
      line-height: 16px;
      color: #99ACD1;
      font-weight: 600;
    }

    &_t-name {
      margin-bottom: 0;
      font-size: 40px;
      line-height: 40px;
      color: $white;
    }

    &_par {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 18px;
      color: #99ACD1;
      font-weight: 600;
    }
  }
}