@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap');

// Animated Leaderboard Page V2 variables
$animated-leaderboard-page-blue1: #001848;
$animated-leaderboard-page-blue2: #2552A7;
$animated-leaderboard-page-blue3: #7187B2;
$animated-leaderboard-page-black1: rgba(#000000, 0.6);
$animated-leaderboard-page-table-gap: 8px;
$animated-leaderboard-page-bg: rgba($animated-leaderboard-page-blue1, 0.85);

// Bootstrap overrids
$darkBlue: #2552A7;
$lightBlue: #4B7EDF;
$yellow: #F8A61C;
$gray: #DDE0E8;
$muted: #6c757d;

$theme-colors: (
        "darkBlue": $darkBlue,
        "lightBlue": $lightBlue,
        "yellow": $yellow,
        "gray": $gray,
        "muted": $muted,
);

// Our Variables
$radius: 7px;

// input
$input-bg: transparent;
$input-border-width: 1px;
$input-border-radius: $radius;
$input-border-color: $darkBlue;
$input-focus-border-color: $lightBlue;
$input-btn-focus-width: 0.05rem;
$input-placeholder-color: rgba(black, 0.5);

// button
$btn-border-radius: $radius;
$btn-border-radius-lg: $radius;

$box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

.box-shadow-manager {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

.card {
  @extend .box-shadow-manager;
  border-radius: $radius;
  overflow: hidden;
}

.btn {
  @extend .px-5;
}


.w-5 {
  width: 20%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: calc(100% / 3);
}

.w-45 {
  width: 45%;
}
