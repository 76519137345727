.scrollable-table {
  //tbody, thead, {
  //  display: block;
  //}
  //
  //tbody {
  //  overflow: auto;
  //  height: 100%;
  //}
}

.invisible-cell {
  max-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: 0;
  color: transparent;
  user-select: none !important;
  //border: hidden;
}

.leader-boards-table-no-cells {
  //position: sticky;
  //top: 0px;
  //margin-top: 100px;
  position: fixed;
  top: 65px;
  width: 100%;

  .table-responsive {
    overflow-y: hidden !important;
  }

  table {
    margin-bottom: 0 !important;
  }

  thead {
    border-bottom-color: white !important;
    border-bottom-width: 2px;
  }

  td, td * {
    @extend .invisible-cell;
    border: hidden;
    opacity: 0;
  }
}

.leader-boards-table-no-header {
  th {
    @extend .invisible-cell;
    border-top: hidden;
    border-bottom: hidden;
  }

  td {}
}
