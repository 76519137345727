.chat-container {
  position: relative;
  //display: flex;
  flex: 1;
}

.chat-input-container {
  position: absolute;
  bottom: 0;
}


.chat-input {
}

$send-button-width: 37px;
.chat-send-button {
  border-radius: 50%;
  height: $send-button-width;
  width: $send-button-width;
  display: flex;
  justify-content: center;
  align-items: center;

  .chat-send-icon {
    font-size: 1.5rem;
    max-height: 24px;
  }
}

.chat-op-1 {
  opacity: 1;

  &:hover {
    opacity: 0.8 !important;
  }
}

.chat-op-06 {
  opacity: 0.6;
}

.chat-bubble-width {
  max-width: 80%;
}

.chat-bubble {
  padding: 10px 15px;
  word-break: break-word;
  hyphens: auto;
}

$c-edge: 15px;

.chat-bubble-other {
  border-radius: $c-edge $c-edge $c-edge 2px;
  background-color: #eaeaea;
  color: $dark;
}

.chat-bubble-own {
  border-radius: $c-edge $c-edge 2px $c-edge;
  background-color: #147efb;
  color: $light;
}

.chat-input-container {
  position: absolute;
  width: 100%;
  background-color: white;
}

.chat-input-container-inner {
  height: 55px;
  width: 100%;
  background-color: white;

  input {
    &:focus {
      border-color: $secondary !important;
      box-shadow: 0 0 0 0.2rem rgba($secondary, 0.25);
    }
  }
}

.chat-alert-icon {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(15px);
  padding: 3px;
  min-width: 24px;
  border-radius: 50%;
  background-color: #fc3d39;
  color: white;
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
