.animated-player-details {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;

  &_container {
    @extend .container;
    position: relative;
    height: 90%;
  }

  &_content {
    height: 100%;
    display: flex;
    flex-direction: column;

    &_bottom {
      flex: 1;
      display: flex;
      flex-direction: column;

      &_table-labels {
        display: flex;
        justify-content: flex-end;
        padding-left: $animated-leaderboard-page-table-gap;
        padding-right: $animated-leaderboard-page-table-gap;
        padding-bottom: $animated-leaderboard-page-table-gap;
        background-color: $animated-leaderboard-page-bg;

        &_tee-time {
          background-color: $animated-leaderboard-page-blue2;
          width: 22%;
        }

        &_hole {
          width: 11%;
        }

        &_hole-shotgun {
          background-color: $animated-leaderboard-page-blue2;
        }

        &_tee-time,
        &_hole {
          height: 32px; // todo replace
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 16px;
            margin-bottom: 0;
            text-align: center;
            color: white;
          }
        }
      }

      &_table-rows {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}