.score-entry-input-display-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  .score-display {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $lightBlue;
  }
}

.score-entry-buttons-row {
  padding-left: -0.25rem;
  padding-right: -0.25rem;
  display: flex;
  flex-wrap: wrap;

  .score-entry-button {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin-bottom: 0.5rem;

    button {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
