.animated-page-video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}