.modal-max-400 {
  max-width: 400px;
}

.custom-modal-container {
  border-radius: $radius;
  overflow: hidden;
  border-width: 0 !important;
  @extend .box-shadow-manager;
}

.custom-modal-header {
  width: 100%;
  position: relative;
  background-color: $darkBlue;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: 34px;

  .close-icon-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.75rem;
    padding-top: 0.25rem;

    .close-icon {
      font-size: 1.3rem;
      max-height: 24px;
      max-width: 24px;
    }
  }

  h5 {
    line-height: 1.45rem;
    font-weight: bold;
  }
}
