$animated-board2-row-animation-offset: 25px;

.animated-board2-row {
  background-color: $animated-leaderboard-page-bg;
  padding-left: $animated-leaderboard-page-table-gap;
  padding-right: $animated-leaderboard-page-table-gap;
  padding-bottom: $animated-leaderboard-page-table-gap; // sticking here to help us calculate the real height of the content

  &_content {
    width: 100%;
    height: 91px; // todo replace
    position: relative;
  }
}