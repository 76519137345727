.top-banner-container {
  border-radius: $radius;
  overflow: hidden;
  margin-top: 0.15rem;
  margin-bottom: 0.5rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

.score-card-pr-container {
  border-radius: $radius;
  overflow: hidden;
  margin-top: 0.5rem;
}
