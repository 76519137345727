@import "./bootstrap-override";

$score-card-table-cell-padding: 0.5rem;

.react-bootstrap-table {
  overflow-x: hidden !important;
  max-width: 100vw;

  table {
    table-layout: initial !important;
    margin-bottom: 0 !important;
  }

  th {
    white-space: nowrap;
  }
}

.leader-board-table-styler {
  @extend .box-shadow-manager;
  border-radius: $radius;
  overflow: hidden;

  thead {
    background-color: $black;
    user-select: none;

    tr {
      th {
        padding: $score-card-table-cell-padding;
        font-weight: 600; // semi-bold
        border-color: $black;
      }
    }
  }

  th {
    border: none;
    font-size: 0.85rem;
    color: $light;

    &:focus {
      outline: none !important;
    }
  }

  td {
    white-space: initial;
    word-break: break-word;
    //border: none;
    font-size: 0.9rem;
    padding-top: 7px;
    padding-bottom: 7px;
    //font-weight: bold;
  }

  tr {
    border-bottom: 1px solid $secondary;

    td {
      border-color: rgba($secondary, 0.25);
      border-top-color: transparent;
      border-bottom: transparent;
    }
  }

  .positions-column {
    padding: $score-card-table-cell-padding;
  }

  .team-name-column {
    padding: $score-card-table-cell-padding;

    &_detailed-score-button {
      display: inline-block;
      //margin-top: 0.4rem;
      font-size: 0.9em;
      width: 100%;
    }
  }

  .score-column {
    padding: $score-card-table-cell-padding;
  }

  .table-bottom-border-helper {
    height: 1px;
    width: 100%;
    background-color: white;
    margin-top: -1px;

    .table-bottom-border-helper-inner {
      height: 1px;
      width: 100%;
      background-color: rgba($secondary, 0.25);
    }
  }
}
