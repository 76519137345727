.landing-bg {
  height: 100vh;
  width: 100%;
  position: relative;
  @extend .bg-dark;

  .landing-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("/images/couple-walking.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.4;
  }

}
