.score-card-header {
  //background-image: url("/images/styled-bg-full.png");
  //background-size: contain;
  //position: relative;
  background-color: black;

  .score-card-header-divider {
    background-color: white;
    width: 1px;
  }

  .score-card-header_bg {
    background-color: rgba(0, 0, 0, 0.33);
  }

  .score-card-header-message {
    font-size: 1.2rem;
  }

  .hole-selector-arrow-container {
    font-size: 2.5rem;
  }

  .score-card-hole-number-label {
    font-size: 1.25rem;
  }

  .score-card-hole-par-label {
    font-size: 1rem;
  }

  @media screen and (max-width: 374px) {
    .hole-selector-arrow-container {
      font-size: 1.75rem;
    }

    .score-card-hole-number-label {
      font-size: 1.1rem;
    }

    .score-card-hole-par-label {
      font-size: 0.85rem;
    }
  }
}

.net-score-sub-script {
  font-size: 0.9rem;
  margin-top: 0.9rem;
}

.score-card-left-columns {
  width: 55%;
}

.score-card-right-columns {
  width: 45%;
}

.score-card-display-column-wrapper {
  h5 {
    font-size: 1.5rem;
  }
}

.score-hard-header-toggle-leader-board-button-container {
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  display: inline-block;
}

.rounded-score-card-header {
  border-radius: $radius;
  overflow: hidden;
}

.score-card-main-display {
  border-radius: $radius;
  overflow: hidden;
  //border: 1px solid $gray;
  @extend .box-shadow-manager;
}

@keyframes pulsating-hexagon {
  0%   {transform: scale(0.9)}
  50%  {transform: scale(1.1)}
  100% {transform: scale(0.9)}
}

.pulsating-score-card-hexagon {
  animation-name: pulsating-hexagon;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}
